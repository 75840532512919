import { useLocation } from "@reach/router";
import { PageProps } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Treatment from "../components/Treatment";


type TitleProps = {
  site: {
    siteMetadata: {
      exampleUrl: string
    }
  }
}

const TreatmentPage: React.FC<PageProps> = () => {
  const testpage = {
    title: "Anwendung",
    description:
      "Wir sind \
    umgeben von Energie, sei es kosmische, Sonnenenergie, elektrische, magnetische oder universelle. Mit einem Reikigrad erhälst du die Kenntniss die universelle Energie zu verwenden. \
    Reiki wird mit den Händen am Körper angewendet. Man geht unterschiedliche Position durch. Oft wird die Stelle warm, die berührt wird. (Das Licht im Foto ist nur zur Illustration) Man \
    kann Reiki bei sich selbst oder anderen anwenden, aber auch Haustiere sind für Reiki sehr empfänglich und werden dadurch meiner \
    Erfahrung nach ruhiger und ausgeglichener.",
  }

  const location = useLocation()

  return (
    <>
      <Seo title="Reiki Studien Beispiele" />
      <Layout location={location}>
        <Treatment slide={false}></Treatment>
      </Layout>
    </>
  )
}

export default TreatmentPage
